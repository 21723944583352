import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'; 

const Footer = () => {
  const currentYear = new Date().getFullYear()
  const { t , i18n} = useTranslation();
  const isRTL = i18n.dir().toLowerCase() === 'rtl';

  return <footer> 
    <Container>
      <Row>
         <Col className='text-center py-3' dir={isRTL ? 'rtl' : 'ltr'}>
         {t('lc')} &copy; {currentYear}  {t('copyright')}
          </Col>
      </Row> 
    </Container>
  </footer>
}

export default Footer