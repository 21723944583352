import { Row, Col } from 'react-bootstrap'
import Loader from '../components/Loader'
import Message from '../components/Message'
//import products from '../products'
//import {useEffect, useState} from 'react';
//import axios from 'axios';

import { useGetProductsQuery } from '../slices/productsApiSlice'
import ProductOnLine from '../components/ProductOnLine'

const HomeScreenOnLine = () => {
  const { data: products, isLoading, error } = useGetProductsQuery(); 
 
  return (
    <>
     {isLoading ? (
      <Loader />
     ): error ? (<Message variant='danger'>{ error?.data?.message || error.error } </Message>) : 
     (<>
          <hr></hr>
          <h1 align="center"> Les dernières formations en ligne </h1>
          <hr color="green"></hr>
        <Row>
           {products.map((product) => (
               <Col key={product._id} sm={12} md={6} lg={4} xl={3}>
                 <ProductOnLine product={product} />
               </Col>
           ))}
        </Row>
    </>) }
    </>
  );
};

export default HomeScreenOnLine