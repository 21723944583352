import React from 'react';
import QRCode from 'qrcode.react';

function Template2Overlay({
  formation_header,
  formation,
  nom,
  CIN,
  date,
  publicKey,
  status,
}) {
  // Convert the date string to a Date object
  const originalDate = new Date(date);

  // Calculate the expiration date by adding 3 years
  const expirationDate = new Date(originalDate);
  expirationDate.setFullYear(expirationDate.getFullYear() + 3);

  // Format the original date as "DD-MM-YYYY"
  const formattedDateString = originalDate.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  // Format the expiration date as "DD-MM-YYYY"
  const formattedExpirationDateString = expirationDate.toLocaleDateString(
    'en-GB',
    {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }
  );

  const qrCodeValue = `${publicKey} - ${status}`;

  return (
    <div className='overlay'>
      <div className='nom2'>
        <h2>{nom}</h2>
      </div>
      <div className='cin2'>
        <h5>{CIN}</h5>
      </div>
      <div className='date2'>
        <h5>{formattedDateString}</h5>
      </div>
      <div className='qr-code-container'>
        <QRCode value={qrCodeValue} className='qr-code2' />
      </div>
      <div className='validite2'>
        <h5>Certification est valide jusqu'au {formattedExpirationDateString}</h5>
      </div>
      <div className='signature2'>
        <img
          src={'/signe.png'}
          alt='Description'
          style={{
            maxWidth: '100%',
            animation: 'rotate360 2s infinite linear',
          }}
        />
      </div>
    </div>
  );
}

export default Template2Overlay;
