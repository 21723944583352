import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Table, InputGroup, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';

const CertificateTable = () => {
  const [certificates, setCertificates] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const { userInfo } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo && (userInfo.isAdmin || userInfo.isOffice)) {
      axios
        .get('/api/certificates', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        })
        .then((response) => {
          // Set the fetched data to the state
          setCertificates(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      navigate('/login');
    }
  }, [userInfo, navigate]);

  const handleDelete = (certificateId) => {
    axios
      .delete(`/api/certificates/${certificateId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      })
      .then((response) => {
        console.log('Certificate deleted:', response.data);
        toast.success('Certificate deleted');
        // Update the state by removing the deleted certificate
        setCertificates(
          certificates.filter(
            (certificate) => certificate._id !== certificateId
          )
        );
      })
      .catch((error) => {
        console.error('Error deleting certificate:', error);
        toast.error('Error deleting certificate');
      });
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredCertificates = certificates.filter(
    (certificate) =>
      certificate.nom.toLowerCase().includes(searchTerm.toLowerCase()) ||
      certificate.CIN.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className='container'>
      <h1>Certificate Verification</h1>
      <InputGroup className='mb-3'>
        <FormControl
          placeholder='Search by Nom or CIN'
          value={searchTerm}
          onChange={handleSearch}
        />
        <InputGroup.Text>
          <FontAwesomeIcon icon={faSearch} />
        </InputGroup.Text>
      </InputGroup>
      <Table striped bordered hover responsive className='table-sm'>
        <thead>
          <tr>
            <th>Formation Header</th>
            <th>Nom</th>
            <th>CIN</th>
            <th>Formation</th>
            <th>Date</th>
            <th>Public Key</th>
            <th> Expiration Date</th>
            <th> Status</th>
            {/*<th>Private Key</th> */}
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredCertificates.map((certificate) => (
            <tr key={certificate._id}>
              <td>{certificate.formation_header}</td>
              <td>{certificate.nom}</td>
              <td>{certificate.CIN}</td>
              <td>{certificate.formation}</td>
              <td>{moment(certificate.date).format('DD MMMM YYYY')}</td>
              <td>{certificate.publicKey}</td>
              <td>{moment(certificate.expirationDate).format('DD MMMM YYYY')}</td>
              <td>{certificate.status}</td>
              {/*<td>{certificate.privateKey}</td> */}
              <td>
                <button
                  className='btn btn-danger'
                  onClick={() => handleDelete(certificate._id)}
                >
                  <i className='fas fa-trash'></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default CertificateTable;
