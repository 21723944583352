import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
  //import Message from '../components/Message'
import Loader from '../components/Loader'
  //import { login } from '../actions/userActions'
  import { Helmet } from 'react-helmet-async';

import { useLoginMutation } from '../slices/usersApiSlice'
import { setCredentials } from '../slices/authSlice'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'; 

import FormContainer from '../components/FormContainer'

const LoginScreen = () => {
  //const location = useLocation()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir().toLowerCase() === 'rtl';

  const dispatch = useDispatch();
  const navigate = useNavigate(); 

  const [login, {isLoading}] = useLoginMutation();

  const { userInfo } = useSelector((state) => state.auth);

  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const redirect = sp.get('redirect') || '/';
  
  
     useEffect(() => {
         if(userInfo) {
             navigate(redirect);
         }
     }, [ userInfo , redirect, navigate ]);
    

  const submitHandler = async (e) => {
    e.preventDefault();
    try{
        const res = await login({ email, password }).unwrap();
        dispatch(setCredentials({ ...res, }));
        navigate(redirect);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
};

  return (
    <FormContainer>
      <h1 dir={isRTL ? 'rtl' : 'ltr'}> {t('signin')} </h1>
      <h6 dir={isRTL ? 'rtl' : 'ltr'}>  {t('invited')}    </h6>

      <Form onSubmit={submitHandler}>

        <Form.Group controlId='email' className="my-3" dir={isRTL ? 'rtl' : 'ltr'}>
          <Form.Label> {t('email')} </Form.Label>
          <Form.Control
            type='email'
            placeholder={t('enteremail')}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='password' className="my-3" dir={isRTL ? 'rtl' : 'ltr'}>
          <Form.Label> {t('mdp')} </Form.Label>
          <Form.Control
            type='password'
            placeholder={t('mdp')}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Button type='submit' variant='primary' className="mt-2" disabled={ isLoading } dir={isRTL ? 'rtl' : 'ltr'}>
        {t('signin')}
        </Button>

        { isLoading && <Loader /> }

      </Form>

      <Row className='py-3'dir={isRTL ? 'rtl' : 'ltr'}> 
      <Col dir={isRTL ? 'rtl' : 'ltr'} >
        {t('new')}<Link to={ redirect ? 
          `/register?redirect=${redirect}` :
           '/register'}>{t('register')}</Link>
        </Col>
      </Row>
      <Helmet>
        <title> Licorne Academy - Login</title>
        <meta
          name='description'
          content='Licorne Academy - Login'
        />
        <link rel='canonical' href='https://licorne-academy.store/login' />
      </Helmet>
    </FormContainer>
  )
}

export default LoginScreen;