import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

//here
//import { useDispatch } from 'react-redux';
//import {useNotPayOrderMutation} from '../slices/ordersApiSlice';

   //import { setCredentialOrder } from '../slices/orderSlice';

//here
//import { useSelector } from 'react-redux';
//import { addToClictopay } from '../slices/clictopaySlice';

const PaymentFailedScreen = () => {
  const { t } = useTranslation();

 //here
 //const { clictopayInfo } = useSelector((state) => state.clictopay);
 //const dispatch = useDispatch();
 //var paiement = "Failed";
 //var orderId = clictopayInfo.orderId;
 //dispatch(addToClictopay({ orderId, paiement }));

   //c la
   //var reponseErreur = 1;

//here
//const [NotPayOrder] = useNotPayOrderMutation();
//NotPayOrder({ orderId, details: {payer: false }});
  
   //const [payOrder] = usePayOrderMutation();
  //const dispatch = useDispatch();
  //if(reponseErreur===1)
  //{
 
 // NotPayOrder({ orderId, details: {payer: reponseErreur }});
 
  //reponseErreur=0;
  //}

  useEffect(() => {
    const redirectTimeout = setTimeout(() => {
      //window.location.href = 'http://localhost:3000/Fail';
      window.location.href = 'https://licorne-academy.store/Fail';
    }, 20000);

    return () => {
      clearTimeout(redirectTimeout);
    };
  }, []);

  return (
    <p> {t('payfail')} </p>
    );
};

export default PaymentFailedScreen;
