import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    langueInfo: localStorage.getItem('langueInfo')
      ? JSON.parse(localStorage.getItem('langueInfo'))
      : {langue:'FR' }
      //: {orderID: '', paiement: ''}
  };

const langueSlice = createSlice({
    name: 'langue',
    initialState,
    reducers: {
        setCredentialLangue: (state, action) => {
            state.langueInfo = action.payload;
            localStorage.setItem('langueInfo', JSON.stringify(action.payload));
        },

    },
});

export const { setCredentialLangue } = langueSlice.actions;

export default langueSlice.reducer;
