import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
//import Message from '../components/Message'
import Loader from '../components/Loader'
//import { login } from '../actions/userActions'

import { useRegisterMutation } from '../slices/usersApiSlice'
import { setCredentials } from '../slices/authSlice'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'; 
import { Helmet } from 'react-helmet-async';

import FormContainer from '../components/FormContainer'

const RegisterScreen = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir().toLowerCase() === 'rtl';
  const dispatch = useDispatch();
  const navigate = useNavigate(); 

  const [register, {isLoading}] = useRegisterMutation();

  const { userInfo } = useSelector((state) => state.auth);

  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const redirect = sp.get('redirect') || '/';
  
  
     useEffect(() => {
         if(userInfo) {
             navigate(redirect);
         }
     }, [ userInfo , redirect, navigate ]);


  const submitHandler = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword){
        toast.error('Passwords do not match');
        return;
    } else {
        try{
            const res = await register({ name, email, password }).unwrap();
            dispatch(setCredentials({ ...res, }));
            navigate(redirect);
        } catch (err) {
          toast.error(err?.data?.message || err.error);
        } 
    }
};

  return (
    <FormContainer>
      <h1 dir={isRTL ? 'rtl' : 'ltr'}> {t('register')} </h1>

      <Form onSubmit={submitHandler}>

      <Form.Group controlId='name' className="my-3" dir={isRTL ? 'rtl' : 'ltr'}>
          <Form.Label > {t('name')} </Form.Label>
          <Form.Control
            type='text'
            placeholder={t('name')}
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='email' className="my-3" dir={isRTL ? 'rtl' : 'ltr'}>
          <Form.Label dir={isRTL ? 'rtl' : 'ltr'}> {t('email')} </Form.Label>
          <Form.Control
            type='email'
            placeholder={t('email')}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='password' className="my-3" dir={isRTL ? 'rtl' : 'ltr'}>
          <Form.Label> {t('mdp')} </Form.Label>
          <Form.Control
            type='password'
            placeholder={t('mdp')}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='confirmPassword' className="my-3" dir={isRTL ? 'rtl' : 'ltr'}>
          <Form.Label> {t('mdpv')} </Form.Label>
          <Form.Control
            type='password'
            placeholder={t('mdpv')}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Button type='submit' variant='primary' className="mt-2" disabled={ isLoading } dir={isRTL ? 'rtl' : 'ltr'} >
        {t('register')} 
        </Button>

        { isLoading && <Loader /> }
      </Form>

      <Row className='py-3' dir={isRTL ? 'rtl' : 'ltr'}>
        <Col>
        {t('haveacc')} <Link to={ redirect ? 
          `/login?redirect=${redirect}` :
           '/login'}> {t('login')} </Link>
        </Col>
      </Row>
      <Helmet>
        <title> Licorne Academy - Register</title>
        <meta
          name='description'
          content='Licorne Academy - Register'
        />
        <link rel='canonical' href='https://licorne-academy.store/register' />
      </Helmet>
    </FormContainer>
    
  )
}

export default RegisterScreen;