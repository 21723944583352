import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Row, Col, ListGroup, Image, Card } from 'react-bootstrap';
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';

//selim
import { useSelector } from "react-redux";

//import { useDispatch } from 'react-redux';
//import { usePaiementMutation } from '../slices/ordersApiSlice';
//import { setCredentials } from '../slices/orderSlice';

import { toast } from 'react-toastify';
import Message from '../components/Message';
import Loader from '../components/Loader';
import {
  useGetOrderDetailsQuery, 
  usePayOrderMutation, 
  useGetPaypalClientIdQuery, 
  //useDeliverOrderMutation,
} from '../slices/ordersApiSlice';

import { useTranslation } from 'react-i18next'; 

const OrderScreen = ({ amount }) => {
  const { id: orderId } = useParams();
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir().toLowerCase() === 'rtl';
  const {
    data: order,
    refetch,
    isLoading,
    error,
  } = useGetOrderDetailsQuery(orderId);

   //selim
   const { deviseInfo } = useSelector((state) => state.devise);
  
  //paiement
  //const dispatch = useDispatch();
  //const [paiement, { isLoading: loadingPaiement }] = usePaiementMutation();
  //const { orderInfo } = useSelector((state) => state.order);

  
  const [payOrder, { isLoading: loadingPay }] = usePayOrderMutation();

  //const [deliverOrder, {isLoading: loadingDeliver}] = useDeliverOrderMutation();
  
  const [{ isPending }, paypalDispatch] = usePayPalScriptReducer();

  //const { userInfo } = useSelector((state) => state.auth);

  const {
    data: paypal,
    isLoading: loadingPayPal,
    error: errorPayPal,
  } = useGetPaypalClientIdQuery();


  useEffect(() => {
    if (!errorPayPal && !loadingPayPal && paypal.clientId) {
      const loadPaypalScript = async () => {
        paypalDispatch({
          type: 'resetOptions',
          value: {
            'client-id': paypal.clientId,
            currency: 'USD',
          },
        });
        paypalDispatch({ type: 'setLoadingStatus', value: 'pending' });
      };
      //paiement
      if (order && !order.isPaid) {
        if (!window.paypal) {
          loadPaypalScript();
        }
      }
    }
  }, [errorPayPal, loadingPayPal, order, paypal, paypalDispatch]);

    
  //const [deliverOrder, { isLoading: loadingDeliver }] = useDeliverOrderMutation();


  function onApprove(data, actions) {
    return actions.order.capture().then(async function (details) {
      try {
        await payOrder({ orderId, details });
        refetch();
        toast.success('Order is paid');
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    });
  }
  

  // TESTING ONLY! REMOVE BEFORE PRODUCTION
  // async function onApproveTest() {
  // await payOrder({ orderId, details: { payer: {} } });
  // refetch();

  // toast.success('Order is paid');
  // }
  
  
  function onError(err) {
    toast.error(err.message);
  }

  function createOrder(data, actions) {
    return actions.order.create({
        purchase_units: [
          {
            amount: { value: order.totalPrice },
          },
        ],
      })
      .then((orderID) => {
        return orderID;
      });
  }

  /*
  const deliverOrderHandler = async () => {
    try {
    await deliverOrder(orderId);
    refetch();
    toast.success('Order delivered');
    } catch (err) {
      toast.error(err?.data?.message || err.message);
    }
  };
  */


  // const handleClick = async (actions) => {
  //   //event.preventDefault();

  //   const date = new Date().toISOString().replace(/-|:|\.\d+/g, '');
  //   const url = `https://ipay.clictopay.com/payment/rest/register.do?amount=${
  //     amount * 1000
  //   }&currency=788&language=fr&orderNumber=${date}&password=n3yH59MtB&returnUrl=https://licorne-academy.store/Success&userName=1749219612&failUrl=https://licorne-academy.store/Fail`;

  //   const response = await fetch(url);
  //   const jsonResponse = await response.json();

  //   //window.location.href = jsonResponse.formUrl;

  //   return actions.order.capture().then(async function (details) {
  //     try {
  //       await payOrder({ orderId, details });
  //       refetch();
  //       toast.success('Order is paid');
  //     } catch (err) {
  //       toast.error(err?.data?.message || err.error);
  //     }
  //   });
  // };

    //const successPaymentHandler = (paymentResult) => {
      //async function successPaymentHandler() {
        //await payOrder({ orderId, details: { payer: {} } });
        //console.log(" + ");
        //console.log(paymentResult);
        //dispatch(payOrder(orderId, paymentResult));
    //};



  return isLoading ? (
    <Loader />
  ) : error ? (
    <Message variant='danger'>{error}</Message>
  ) : (
    <>
      <h1>Commande {order._id}</h1>
      <Row>
        <Col md={8}>
          <ListGroup variant='flush'>
            <ListGroup.Item>
              <h2>{t('orders')}</h2>
              <p>
                <strong>{t('name')}: </strong> {order.shippingAddress.namePrename}
              </p>
              <p>
                <strong>{t('email')} : </strong>{' '}
                <a href={`mailto:${order.shippingAddress.mail}`}>{order.shippingAddress.mail}</a>
              </p>
              <p>
                <strong>{t('address')} : </strong>
                {order.shippingAddress.adress}
              </p>
              <p>
                <strong>{t('phonenumber')} : </strong>
                {order.shippingAddress.tel}
              </p>
              
              {/* {order.isDelivered ? (
                <Message variant='success'>
                  Délivré le {order.deliveredAt}
                </Message>
              ) : (
                <Message variant='danger'>Not Delivered</Message>
              )} */}

            </ListGroup.Item>

            <ListGroup.Item>
              <h2>{t('mode')}</h2>
              <p>
                <strong>{t('method')} : </strong>
                {order.paymentMethod}
              </p>
              {order.isPaid ? (
                <Message variant='success'>Payé le {order.paidAt}</Message>
              ) : (
                <Message variant='danger'>N'est pas payé</Message>
              )}
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>{t('ordsum')}</h2>
              {order.orderItems.length === 0 ? (
                <Message>{t('emptycart')}</Message>
              ) : (
                <ListGroup variant='flush'>
                  {order.orderItems.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <Row>
                        <Col md={1}>
                          <Image
                            src={item.image}
                            alt={item.name}
                            fluid
                            rounded
                          />
                        </Col>
                        <Col>
                          <Link to={`/product/${item.product}`}>
                            {item.name}
                          </Link>
                        </Col>
                            {/* selim */}
                            {/* <Col md={4}>
                              {item.qty} x ${item.price} = ${item.qty * item.price}
                            </Col> */}
                             {deviseInfo === "D_tn" && (
                               <Col md={4}>
                               {t('Price')}  
                               {item.qty} x ${item.price} = {item.qty * item.price} {deviseInfo}
                              </Col>
                              )
                              }
                             {deviseInfo === "Euro" && (
                               <Col md={4}>
                               {t('Price')}  
                               {item.qty} x ${item.priceEur} = {item.qty * item.priceEur} {deviseInfo}
                              </Col>
                              )
                              }
                              {deviseInfo === "Dolar" && (
                               <Col md={4}>
                               {t('Price')}  
                               {item.qty} x ${item.priceDol} = {item.qty * item.priceDol} {deviseInfo}
                              </Col>
                              )
                              }
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={4}>
          <Card>
            <ListGroup variant='flush'>
              <ListGroup.Item>
                <h2 dir={isRTL ? 'rtl' : 'ltr'}>{t('ordsum')} </h2>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Articles</Col>
                  {/* selim */}
                  {/* <Col>${order.itemsPrice}</Col> */}
                  {deviseInfo === "D_tn" && (
                      <Col>
                        <strong>{order.itemsPrice} {deviseInfo}</strong>
                      </Col>
                      )
                      }
                      {deviseInfo === "Euro" && (
                      <Col>
                        <strong>{order.itemsPriceEur} {deviseInfo}</strong>
                      </Col>
                      )
                      }
                      {deviseInfo === "Dolar" && (
                      <Col>
                        <strong>{order.itemsPriceDol} {deviseInfo}</strong>
                      </Col>
                      )
                      }
                </Row>
              </ListGroup.Item>
              {/* <ListGroup.Item>
                <Row>
                  <Col>Livraison</Col>
                  <Col>${order.shippingPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Tax</Col>
                  <Col>${order.taxPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Total</Col>
                    <Col>${order.totalPrice}</Col> 
                   selim 
                  {deviseInfo === "D_tn" && (
                      <Col>
                        <strong>{order.totalPrice} {deviseInfo}</strong>
                      </Col>
                      )
                      }
                      {deviseInfo === "Euro" && (
                      <Col>
                        <strong>{order.totalPriceEur} {deviseInfo}</strong>
                      </Col>
                      )
                      }
                      {deviseInfo === "Dolar" && (
                      <Col>
                        <strong>{order.totalPriceDol} {deviseInfo}</strong>
                      </Col>
                      )
                      }
                </Row>
              </ListGroup.Item> */}


             {
             //order.paymentMethod === "PayPal" ? 
             
             !order.isPaid && (
                <ListGroup.Item>
                  {loadingPay && <Loader />}

                  {isPending ? (
                    <Loader />
                  ) : (
                    <div>
                      {/* THIS BUTTON IS FOR TESTING! REMOVE BEFORE PRODUCTION! */}
                        {/* <Button
                        style={{ marginBottom: '10px' }}
                        onClick={onApproveTest}
                       >
                        Test Pay Order
                       </Button>  */}
                      
                      {/* selim */}
                      {(deviseInfo === "Euro" || deviseInfo === "Dolar") ? (
                      <div>
                        <PayPalButtons
                          createOrder={createOrder}
                          onApprove={onApprove}
                          onError={onError}
                        ></PayPalButtons>
                      </div> 
                       ) : (
                        <h6>La devise de paiement doit être en Euro ou en Dolar</h6>
                      )}
                    </div>
                  )}
                </ListGroup.Item>
              //)


             )

              }


              {/* {loadingDeliver && <Loader />}

              {userInfo &&
                userInfo.isAdmin &&
                order.isPaid &&
                !order.isDelivered && (
                 <ListGroup.Item>
                   <Button
                   type='button'
                   className='btn btn-block'
                   onClick={deliverOrderHandler}
                   >
                   Mark As Delivered
                  </Button>
                </ListGroup.Item>
              )} */}

              
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default OrderScreen;
