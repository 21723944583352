import React, { useState, useEffect } from 'react';
import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useParams } from 'react-router-dom';
function Meet() {
   const { roomID } = useParams(); // Extracting roomID from the URL
   const APP_ID = 324210630;
   const SERVER_SECRET = 'a6984b005edb27830c5fca42d0a7dff4';
   const [roomName, setRoomName] = useState(roomID || '');
   const [joinedRoom, setJoinedRoom] = useState(!!roomID);

   // Existing useEffect logic can stay the same or be adjusted
   useEffect(() => {
     if (roomID) {
       setJoinedRoom(true);
     }
   }, [roomID]);

   const handleSubmit = (e) => {
     e.preventDefault();
     if (roomName.trim()) {
       const newPath = `/meet/${roomName}`;
       window.history.pushState({}, '', newPath);
       setJoinedRoom(true);
     }
   };

   const roomRef = (element) => {
     if (element && joinedRoom) {
       const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
         APP_ID,
         SERVER_SECRET,
         roomName,
         Date.now().toString(),
         'Participant'
       );

       const zp = ZegoUIKitPrebuilt.create(kitToken);

       zp.joinRoom({
         container: element,
         sharedLinks: [
           {
             name: 'Room Link',
             url: window.location.href,
           },
         ],
         scenario: {
           mode: ZegoUIKitPrebuilt.GroupCall,
         },
       });
     }
   };

   return (
     <div>
       {!joinedRoom ? (
         <div className='row justify-content-center'>
           <div className='col-md-6'>
             <form onSubmit={handleSubmit}>
               <div className='mb-3'>
                 <h3>Welcome To Licorne Academy's Meeting App</h3>
                 <br />
                 <label htmlFor='roomName' className='form-label'>
                   Enter Room Name
                 </label>
                 <input
                   type='text'
                   className='form-control'
                   id='roomName'
                   value={roomName}
                   onChange={(e) => setRoomName(e.target.value)}
                   required
                 />
               </div>
               <button type='submit' className='btn btn-primary'>
                 Join Room
               </button>
             </form>
           </div>
         </div>
       ) : (
         <div style={{ height: '100vh' }} ref={roomRef} />
       )}
     </div>
   );
}

export default Meet;
