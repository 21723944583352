import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    orderInfo: localStorage.getItem('orderInfo')
      ? JSON.parse(localStorage.getItem('orderInfo'))
      //: {orderID: '', paiement: ''}
      : null,
  };

const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        setCredentialOrder: (state, action) => {
            state.orderInfo = action.payload;
            localStorage.setItem('orderInfo', JSON.stringify(action.payload));
        },

    },
});

export const { setCredentialOrder } = orderSlice.actions;

export default orderSlice.reducer;
