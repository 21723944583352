import React, { useState, useRef, useEffect } from 'react';
import '../certificate.css';
import 'bootstrap/dist/css/bootstrap.css';
import { Row } from 'react-bootstrap';
import Template3Overlay from './Template3Overlay';
import Template4Overlay from './Template4Overlay';
import Template2Overlay from './Template2Overlay';
import ReactToPrint from 'react-to-print';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrintContent = React.forwardRef((props, ref) => {
  const { imageUrl, formation_header, nom, CIN, formation, date } = props;
  const [publicKey, setPublicKey] = useState('');
  const [status, setStatus] = useState('');
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.auth);
  useEffect(() => {
    if (userInfo && (userInfo.isAdmin || userInfo.isOffice)) {
      setPublicKey(props.publicKey);
      setStatus(props.status);
      // Only allow access for admins
      // Fetch necessary data or perform any other actions for admins
    } else {
      // Redirect to login page if not admin
      navigate('/login');
    }
    // Update the publicKey when the prop value changes
  }, [props.publicKey, props.status, navigate, userInfo]);

  return (
    <div className='middle-side' ref={ref}>
      <div className='template-preview'>
        {imageUrl && (
          <div className='image-container'>
            <img src={imageUrl} alt='Middle Side' className='img-fluid' />
            {imageUrl === 'maitre.jpg' && (
              <Template2Overlay
                nom={nom}
                CIN={CIN}
                formation={formation}
                date={date}
                publicKey={publicKey}
                status={status}
              />
            )}
            {imageUrl === 'pnl.jpg' && (
              <Template3Overlay
                formation_header={formation_header}
                nom={nom}
                CIN={CIN}
                formation={formation}
                date={date}
                publicKey={publicKey}
                status={status}
              />
            )}
            {imageUrl === 'Coach.jpg' && (
              <Template4Overlay
                nom={nom}
                CIN={CIN}
                formation={formation}
                date={date}
                publicKey={publicKey}
                status={status}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
});

function CertificateGenerator() {
  const [imageUrl, setImageUrl] = useState('');
  const [formation_header, setFormationHeader] = useState('');
  const [nom, setNom] = useState('');
  const [CIN, setCIN] = useState('');
  const [formation, setFormation] = useState('');
  const [date, setDate] = useState('');
  const [publicKey, setPublicKey] = useState('');
  const [status, setStatus] = useState('');
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  const handleFormationHeaderChange = (event) => {
    setFormationHeader(event.target.value);
  };

  const handleNomChange = (event) => {
    setNom(event.target.value);
  };

  const handleCINChange = (event) => {
    setCIN(event.target.value);
  };

  const handleFormationChange = (event) => {
    setFormation(event.target.value);
  };

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  const templateUrls = ['maitre.jpg', 'pnl.jpg', 'Coach.jpg'];

  const apiUrl = '/api/certificates';

  const saveCertificate = () => {
    if (imageUrl && formation_header && nom && CIN && formation && date) {
      const certificateData = {
        imageUrl,
        formation_header,
        nom,
        CIN,
        formation,
        date,
      };

      fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
        body: JSON.stringify(certificateData),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log('Certificate saved:', data);
          const { publicKey } = data.createdCertificate; // Access publicKey from createdCertificate
          setPublicKey(publicKey);
          const { status } = data.createdCertificate; // Access status from createdCertificate
          setStatus(status);
          toast.success('Certificate saved');
        })

        .catch((error) => {
          console.error('Error saving certificate:', error);
          toast.error('Error saving certificate'); // Display error message
        });
    }
  };

  const printRef = useRef();
  return (
    <Row style={{ display: 'flex' }} className='roww'>
      <div className='col-2 left-side'>
        <h2>Choose Your Template</h2>
        <br />
        <div
          className='templates'
          style={{ maxHeight: '500px', overflow: 'auto' }}
        >
          {templateUrls.map((templateUrl, index) => (
            <div key={index}>
              <img
                src={templateUrl}
                alt={`Template ${index + 1}`}
                className={`img-fluid template-image ${
                  imageUrl === templateUrl ? 'selected' : ''
                }`}
                onClick={() => setImageUrl(templateUrl)}
                style={{ marginBottom: '50px' }}
              />
            </div>
          ))}
        </div>
      </div>
      <PrintContent
        ref={printRef}
        imageUrl={imageUrl}
        formation_header={formation_header}
        nom={nom}
        CIN={CIN}
        formation={formation}
        date={date}
        publicKey={publicKey}
        status={status}
      />
      <div className='col-3 right-side'>
        <div className='logo'>
          <h6>Header</h6>
          <input
            type='text'
            value={formation_header}
            onChange={handleFormationHeaderChange}
            className='form-control'
            placeholder='Header'
          />

          <h6>Nom & Prénom</h6>
          <input
            type='text'
            value={nom}
            onChange={handleNomChange}
            className='form-control'
            placeholder='Nom & Prénom'
          />
          <h6>CIN</h6>
          <input
            type='text'
            value={CIN}
            onChange={handleCINChange}
            className='form-control'
            placeholder='CIN'
          />
          <h6>Formation</h6>
          <input
            type='text'
            value={formation}
            onChange={handleFormationChange}
            className='form-control'
            placeholder='Formation'
          />
          <h6>Date De livraison Du Diplome</h6>
          <input
            type='date'
            value={date}
            onChange={handleDateChange}
            className='form-control'
            placeholder='Date'
          />
          <br />
          <button onClick={saveCertificate} className='btn btn-primary'>
            Save Certificate
          </button>
          <ReactToPrint
            trigger={() => <button className='btn btn-secondary'>Print</button>}
            content={() => printRef.current}
          />
        </div>
      </div>
    </Row>
  );
}

export default CertificateGenerator;
