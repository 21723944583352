import { useState } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { Form, Row, Col, Image, ListGroup, Card, Button } from 'react-bootstrap'
//import products from '../products'
import {useDispatch} from 'react-redux'
import Rating from '../components/Rating';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { useGetProductDetailsQuery } from '../slices/productsApiSlice';
import {addToCart} from '../slices/cartSlice'

//import { useState, useEffect } from 'react';
//import axios from 'axios'

const ProductScreen = () => {
  
  //const [product, setProduct] = useState({});

  const { id: productId } = useParams();
  //const product = products.find((p) => p._id === productId)

  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  /*
  useEffect(() => {
     const fetchProduct = async () => {
      const { data } = await axios.get(`/api/products/${productId}`);
      setProduct(data);
     }
     fetchProduct();
  }, [productId]);
  */
   
    const [qty, setQty] = useState(1);

   
    const { data: product, isLoading, error } = useGetProductDetailsQuery
    (productId);

         //pour la partie cart aussi
         const addToCartHandler = () => {
          dispatch(addToCart({ ...product, qty }));
          navigate('/cart');
         }   
    

    return(
    <>
      <Link className='btn btn-light my-3' to='/'>
         Retour
      </Link>

      { isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{ error?.data?.message || error.error } </Message>
      ) : (<Row>
        <Col md={5}>
           <Image src={product.image} alt={product.name}  fluid />
        </Col>
        <Col md={4}>
        <ListGroup variant='flush'>
             <ListGroup.Item>
                <h6>{product.name}</h6>
             </ListGroup.Item>
             <ListGroup.Item>
                 <Rating value={product.rating} text={`${product.numReviews} reviews`} />
             </ListGroup.Item>
             <ListGroup.Item>
                 Price: ${product.price}
             </ListGroup.Item>
             <ListGroup.Item>
                 Description: {product.description}
             </ListGroup.Item>
        </ListGroup>
        </Col>
        <Col md={3}>
            <Card>
              <ListGroup variant='flush'>
                 <ListGroup.Item>
                    <Row>
                        <Col>Price:</Col>
                        <Col>
                          <strong>${product.price}</strong>
                        </Col>
                    </Row>
                 </ListGroup.Item>
                 <ListGroup.Item>
                    <Row>
                        <Col>Status:</Col>
                        <Col>
                          <strong>{product.countInStock > 0 ? 'In Stock' :
                          'Out of Stock'}</strong>
                        </Col>
                    </Row>
                 </ListGroup.Item>

            {product.countInStock > 0 && (
            <ListGroup.Item>
              <Row>
                <Col>Qty</Col>
                <Col>
                  <Form.Control
                    as='select'
                    value={qty}
                    onChange={(e) => setQty(Number(e.target.value))}>
                      {[...Array(product.countInStock).keys()].map((x) => (
                        <option key={x + 1} value={x + 1}>
                          {x + 1}
                        </option>
                      ))}
                    </Form.Control>
                </Col>
              </Row>
            </ListGroup.Item>
          )}

                 <ListGroup.Item>
                     <Button
                       className='btn-block'
                       type='button'
                       disabled={product.countInStock === 0}
                       onClick={addToCartHandler}
                     >
                        Add To cart
                     </Button>
                 </ListGroup.Item>
              </ListGroup>
            </Card>
        </Col>
      </Row>) }   
    </>
  );
};

export default ProductScreen