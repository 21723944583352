import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from './slices/apiSlice';

import cartSliceReducer from './slices/cartSlice';

//couleur
import clictopaySliceReducer from './slices/clictopaySlice';

import authSliceReducer from './slices/authSlice'; 

import orderSliceReducer from './slices/orderSlice'; 

import langueSliceReducer from './slices/langueSlice'; 
import deviseSliceReducer from './slices/deviseSlice'; 

const store =  configureStore ({
  reducer: {
  [apiSlice.reducerPath]: apiSlice.reducer,
  cart: cartSliceReducer,
  auth: authSliceReducer,
  order: orderSliceReducer,
  langue: langueSliceReducer,
  devise: deviseSliceReducer,
  //couleur
  clictopay: clictopaySliceReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat
  (apiSlice.middleware),
  devTools: true,
});

export default store;