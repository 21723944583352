import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    deviseInfo: localStorage.getItem('deviseInfo')
      ? JSON.parse(localStorage.getItem('deviseInfo'))
      : {devise:'D_TN' }
      //: {orderID: '', paiement: ''}
  };

const deviseSlice = createSlice({
    name: 'devise',
    initialState,
    reducers: {
        setCredentialDevise: (state, action) => {
            state.deviseInfo = action.payload;
            localStorage.setItem('deviseInfo', JSON.stringify(action.payload));
        },

    },
});

export const { setCredentialDevise } = deviseSlice.actions;

export default deviseSlice.reducer;
