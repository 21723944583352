import React from 'react'
import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
//import Rating from './Rating'

const ProductOnLine = ( {product} ) => {
  return (
    <Card className='my-3 p-3 rounded'>
       <Link to={`/product/online/${product._id}`}>
          <Card.Img src={product.image} variant='top' />
       </Link>

      <Card.Body>
      <Link to={`/product/online/${product._id}`}>
          <Card.Title className='product-title' as='div'>
            <strong>{product.name}</strong>
          </Card.Title>
      </Link> 
      {/*
      <Card.Text as='div'>
           <Rating value={product.rating} text={`${product.numReviews} reviews`} />
      </Card.Text>  
      */}    

       <Card.Text as='h3'>Prix : {product.price}
       </Card.Text>

       <Card.Text as='h3'>Auteur : {product.author}
       </Card.Text>

      </Card.Body>
    </Card>
  )
}

export default ProductOnLine