import React from 'react'
import { Outlet, Navigate } from "react-router-dom"
import { useSelector } from "react-redux"

const OfficeRoute = () => {
  const { userInfo } = useSelector(state => state.auth)

  return userInfo && userInfo.isOffice ? (
  <Outlet /> 
  ) : ( <Navigate to="/login" replace />
  );
}

export default OfficeRoute