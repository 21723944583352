//couleur
//import React, { useState } from 'react';

import { Button } from 'react-bootstrap';

import { useDispatch } from 'react-redux';

  //c la
 import {usePayOrderMutation} from '../slices/ordersApiSlice';
 //import { setCredentialOrder } from '../slices/orderSlice';
//selim  
//import {useClictopayMutation} from '../slices/ordersApiSlice';

import { useTranslation } from 'react-i18next'; // Import useTranslation hook

//couleur
import { addToClictopay } from '../slices/clictopaySlice';

const ClickToPay = ({ amount, orderId }) => {
  const { t } = useTranslation();

  //selim
  //const [IdOrder, setIdOrder] = useState('')
  //const [Paiement, setPaiement] = useState('')

  //couleur
  //const [IdOrder, setIdOrder] = useState(orderId)

  //c la
  const [payOrder] = usePayOrderMutation();
  //selim
  //const [clictopay] = useClictopayMutation();

  const dispatch = useDispatch();
  //const { orderInfo } = useSelector((state) => state.order);
  
  //var reponseErreur = 1;
  //selim
  var paiement = "depart";

  const handleClick = async (event) => {
    event.preventDefault();
    const date = new Date().toISOString().replace(/-|:|\.\d+/g, '');
    // const url = `https://ipay.clictopay.com/payment/rest/register.do?amount=${
    //   amount * 1000
    // }&currency=788&language=fr&orderNumber=${date}&password=n3yH59MtB&returnUrl=https://licorne-academy.store/Success&userName=1749219612&failUrl=https://licorne-academy.store/Fail`;
    
     const url = `https://ipay.clictopay.com/payment/rest/register.do?amount=${
       amount * 1000
     }&currency=788&language=fr&orderNumber=${date}&password=n3yH59MtB&returnUrl=http://localhost:3000/Success&userName=1749219612&failUrl=http://localhost:3000/Fail`;
    
     
        //const res = await login({ email, password }).unwrap();
     //console.log(res);
        //dispatch(setCredentials({ ...res, }));
     //couleur
     dispatch(addToClictopay({ ...{orderId, paiement} }));

     payOrder({ orderId, details: {payer: true }});

    const response = await fetch(url);
    const jsonResponse = await response.json();

    window.location.href = jsonResponse.formUrl;

    //reponseErreur = jsonResponse.errorCode;

    //var orderStatus = jsonResponse.orderStatus;

    
    //const OrderStatut = jsonResponse.OrderStatus;
    //const messageErreur = jsonResponse.ErrorMessage;

    //var returnUrl = jsonResponse.returnUrl;
    //const res = payOrder({ orderId, details: {payer: jsonResponse.formUrl, OrdreStatut: jsonResponse.OrderStatus, CodeErreur: jsonResponse.errorCode, MessageError: jsonResponse.ErrorMessage }});
    
    //selim   ici 0
    //if(reponseErreur === 0)
    //{
    //c la
    //const res1 = payOrder({ orderId, details: {payer: reponseErreur }});
    
    //dispatch(setCredentialOrder({...res1}));

            //selim
        //const res2 = clictopay({ orderId, reponseErreur, paiement });
        //dispatch(setCredentialOrder({ ...res2, }));
     
     //const res = await clictopay({ orderId, reponseErreur, paiement }).unwrap();
     //var test = "651429f7b3a08fe6ea17cb4f";

    //}
  };

  //if(reponseErreur === 0)
  //{
  //const res = payOrder({ orderId, details: reponseErreur});
  //dispatch(setCredentials({...res}));
  //}

  return (
    <>
      <form onSubmit={handleClick}>
        <Button type='submit' className='btn-block'>
        {t('pay')}
        </Button>
      </form>
    </>
  );
};

export default ClickToPay;