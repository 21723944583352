export const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
}

export const updateCart = (state) => {

    
    //calculer items price
    state.itemsPrice = addDecimals(state.cartItems.reduce((acc, item) =>
    acc + item.price * item.qty, 0));

        //selim
        state.itemsPriceEur = addDecimals(state.cartItems.reduce((acc, item) =>
        acc + item.priceEur * item.qty, 0));
        state.itemsPriceDol = addDecimals(state.cartItems.reduce((acc, item) =>
        acc + item.priceDol * item.qty, 0));

    //calculer shipping price (if order is over 100dollars then free
    //else 10$ shipping)
    state.shippingPrice = addDecimals(state.itemsPrice > 100 ? 0 : 0);

    //calculer tax price (15% tax)
    state.taxPrice = addDecimals(Number((0 * state.itemsPrice).toFixed(2)));

    //calculer total price
    state.totalPrice = (
     Number(state.itemsPrice) +
     Number(state.shippingPrice) +
     Number(state.taxPrice) 
    ).toFixed(2);

        //selim
        state.totalPriceEur = (
            Number(state.itemsPriceEur) +
            Number(state.shippingPrice) +
            Number(state.taxPrice) 
           ).toFixed(2);
        state.totalPriceDol = (
            Number(state.itemsPriceDol) +
            Number(state.shippingPrice) +
            Number(state.taxPrice) 
           ).toFixed(2);

    //enregistrer dans le local storage
    localStorage.setItem('cart', JSON.stringify(state));
  
    return state;
}

