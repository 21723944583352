import React, { useState, useEffect } from 'react';
import { Form, Button, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FormContainer from '../components/FormContainer';
import { savePaymentMethod } from '../slices/cartSlice';
import CheckoutSteps from '../components/CheckoutSteps';
import { useTranslation } from 'react-i18next';
const PaymentScreen = () => {
  
  const [paymentMethod, setPaymentMethod] = useState('PayPal');
  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const cart = useSelector((state) => state.cart);
  const { shippingAddress } = cart;
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir().toLowerCase() === 'rtl';

  const { deviseInfo } = useSelector((state) => state.devise);

  useEffect(() => {
    if (!shippingAddress) {
      navigate('/shipping');
    }
  }, [shippingAddress, navigate]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(savePaymentMethod(paymentMethod));
    navigate('/placeorder');
  };
  
 
  return (
    <FormContainer>
      <CheckoutSteps step1 step2 step3 />
      <h1 dir={isRTL ? 'rtl' : 'ltr'}>{t('mode')}</h1>
      <Form onSubmit={submitHandler} dir={isRTL ? 'rtl' : 'ltr'}>
        <Form.Group>
          <Form.Label as='legend'>{t('method')}</Form.Label>
          

          {deviseInfo === "D_tn" && (
          <Col>
            <Form.Check
              type='switch'
              className='my-2'
              label='ClictoPay Tunisia'
              id='ClicToPay'
              name='paymentMethodclic'
              value='ClicToPay'
              //checked
              onChange={(e) => setPaymentMethod(e.target.value)}
            ></Form.Check>

             <Form.Check
              type='switch'
              disabled
              className='my-2'
              label='Paypal'
              id='PayPal'
              name='paymentMethod'
              value='PayPal'
              //checked
              onChange={(e) => setPaymentMethod(e.target.value)}
            ></Form.Check>

             <Form.Check
              type='switch'
              disabled
              className='my-2'
              label='Stripe'
              id='Stripe'
              name='paymentMethodStripe'
              value='Stripe'
              //checked
              onChange={(e) => setPaymentMethod(e.target.value)}
            ></Form.Check>
          </Col>
          )
         }

         {(deviseInfo === "Dolar" || deviseInfo === "Euro") && (
           <Col>
           <Form.Check
             type='switch'
             className='my-2'
             label='ClictoPay Tunisia'
             id='ClicToPay'
             name='paymentMethodclic'
             value='ClicToPay'
             disabled
             onChange={(e) => setPaymentMethod(e.target.value)}
           ></Form.Check>

            <Form.Check
             type='switch'
             className='my-2'
             label='Paypal'
             id='PayPal'
             name='paymentMethod'
             value='PayPal'
             //checked
             onChange={(e) => setPaymentMethod(e.target.value)}
           ></Form.Check>

            <Form.Check
             type='switch'
             className='my-2'
             label='Stripe'
             id='Stripe'
             name='paymentMethodStripe'
             value='Stripe'
             //checked
             onChange={(e) => setPaymentMethod(e.target.value)}
           ></Form.Check>
         </Col>
         )
         }
          
         
        </Form.Group>
        <br />
        <Button type='submit' variant='primary'>
        {t('Continue')}
        </Button>
      </Form>
    </FormContainer>
  );
};

export default PaymentScreen;
