import React from 'react';
import { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FormContainer from '../components/FormContainer';
import { saveShippingAddress } from '../slices/cartSlice';
import CheckoutSteps from '../components/CheckoutSteps';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { Helmet } from 'react-helmet-async';

const ShippingScreen = () => {
  const cart = useSelector((state) => state.cart);
  const { shippingAddress } = cart;
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir().toLowerCase() === 'rtl';

  const [namePrename, setNamePrename] = useState(
    shippingAddress?.namePrename || ''
  );
  const [adress, setAdress] = useState(shippingAddress?.adress || '');
  const [mail, setMail] = useState(shippingAddress?.mail || '');
  const [tel, setTel] = useState(shippingAddress?.tel || '+216');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    //console.log('submit');
    dispatch(saveShippingAddress({ namePrename, adress, mail, tel }));
    navigate('/payment');
  };

  return (
    <FormContainer>
      {<CheckoutSteps step1 step2 />}
      <h1 dir={isRTL ? 'rtl' : 'ltr'}>{t('shipping')}</h1>
      <Form onSubmit={submitHandler} dir={isRTL ? 'rtl' : 'ltr'}>
        <Form.Group controlId='namePrename' className='my-2'>
          <Form.Label>{t('name&lastname')}</Form.Label>
          <Form.Control
            type='text'
            placeholder={t('name&lastname')}
            value={namePrename}
            onChange={(e) => setNamePrename(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='adress' className='my-2'>
          <Form.Label>{t('adress&country')}</Form.Label>
          <Form.Control
            type='text'
            placeholder={t('adress&country')}
            value={adress}
            onChange={(e) => setAdress(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='mail' className='my-2'>
          <Form.Label>{t('email')}</Form.Label>
          <Form.Control
            type='text'
            placeholder={t('email')}
            value={mail}
            onChange={(e) => setMail(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='tel' className='my-2'>
          <Form.Label>{t('phonenumber')}</Form.Label>
          <PhoneInput
            placeholder={t('phonenumber')}
            value={tel}
            defaultCountry='TN'
            onChange={setTel}
          />
        </Form.Group>

        <Button type='submit' variant='primary' className='my-2'>
          {t('Continue')}
        </Button>
      </Form>
      <Helmet>
        <title> Licorne Professional Academy - Shipping</title>
        <meta
          name='description'
          content='Licorne Professional Academy - Shipping'
        />
        <link rel='canonical' href='https://licorne-academy.store/shipping' />
      </Helmet>
    </FormContainer>
  );
};

export default ShippingScreen;
