import React from 'react'
import HeaderMobile from './HeaderMobile';
import HeaderDesktop from './HeaderDesktop';
const Header = () => {
     const width = window.innerWidth;
     const breakpoint = 620;
     //console.log(width); 

  return (
     width < breakpoint ? <HeaderMobile /> : <HeaderDesktop />
     //<HeaderDesktop />
     )
}

export default Header