import { apiSlice } from './apiSlice';
import  { ORDERS_URL, PAYPAL_URL } from '../constants';

export const ordersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createOrder: builder.mutation({
        query: (order) => ({
            url: ORDERS_URL,
            method: 'POST',
            body: {...order}
        }),
    }),
    
    getOrderDetails: builder.query({
      query: (id) => ({
        url: `${ORDERS_URL}/${id}`,
      }),
      keepUnusedDataFor: 5,
    }),
    //paiement
    payOrder: builder.mutation({
      query: ({ orderId, details }) => ({
        url: `${ORDERS_URL}/${orderId}/pay`,
        method: 'PUT',
        body: { ...details},
      }),
    }),

    //c la
    notPayOrder: builder.mutation({
      query: ({ orderId, details }) => ({
        url: `${ORDERS_URL}/${orderId}/notpay`,
        method: 'PUT',
        body: { ...details},
      }),
    }),


    //paiement
    getPaypalClientId: builder.query({
      query: () => ({
        url: PAYPAL_URL,
      }),
      keepUnusedDataFor: 5,
    }),
    
    getMyOrders: builder.query({
      query: () => ({
        url: `${ORDERS_URL}/mine`,
      }),
      keepUnusedDataFor: 5,
    }),
    
    getOrders: builder.query({
      query: () => ({
        url: ORDERS_URL,
      }),
      keepUnusedDataFor: 5,
    }),
    /*
    deliverOrder: builder.mutation({
      query: (orderId) => ({
        url: `${ORDERS_URL}/${orderId}/deliver`,
        method: 'PUT',
      }),
    }),
    */

    //selim
    Clictopay: builder.mutation({
      query: (data) => ({
        url: `${ORDERS_URL}/${data.orderId}/clictopay`,
        method: 'POST',
        body: data,
      }),
    }),

  }),
});

/*
export const { useCreateOrderMutation,useGetOrderDetailsQuery,
  usePayOrderMutation,
  useGetPaypalClientIdQuery, useGetMyOrdersQuery, 
  useGetOrdersQuery, useDeliverOrderMutation,
 } = ordersApiSlice;
 */
 export const { useCreateOrderMutation, useGetOrderDetailsQuery,
  usePayOrderMutation, useGetPaypalClientIdQuery,
  useGetMyOrdersQuery, useGetOrdersQuery,
  //c la
  useNotPayOrderMutation,
//selim
useClictopayMutation, } = ordersApiSlice;