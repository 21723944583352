import React from 'react'
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Button, Row, Col, ListGroup, Image, Card } from 'react-bootstrap';
import { toast } from 'react-toastify';
import CheckoutSteps from '../components/CheckoutSteps';
import Message from '../components/Message';
import Loader from '../components/Loader';
import { useCreateOrderMutation } from '../slices/ordersApiSlice';
import { clearCartItems } from '../slices/cartSlice';
import { useTranslation } from 'react-i18next';


const PlaceOrderScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const cart = useSelector((state) => state.cart);
    const { t , i18n} = useTranslation();
    const isRTL = i18n.dir().toLowerCase() === 'rtl';
    //selim
     const { deviseInfo } = useSelector((state) => state.devise);

    const [createOrder, {isLoading, error}] = useCreateOrderMutation();

    useEffect(() => {
        if (!cart.shippingAddress) {
          navigate('/shipping');
        }
        else if (!cart.paymentMethod) {
          navigate('/payment');
        }
      }, [cart.paymentMethod, cart.shippingAddress, navigate]);

      
      const placeOrderHandler = async () => {
        try {
            const res = await createOrder({
                orderItems: cart.cartItems,
                shippingAddress: cart.shippingAddress,
                paymentMethod: cart.paymentMethod,
                itemsPrice: cart.itemsPrice,
                shippingPrice: cart.shippingPrice,
                taxPrice: cart.taxPrice,
                totalPrice: cart.totalPrice,
            }).unwrap();
            dispatch(clearCartItems());
            if(cart.paymentMethod === "PayPal")
            navigate(`/orderPayPal/${res._id}`);
            else if(cart.paymentMethod === "ClicToPay")
            navigate(`/orderClicToPay/${res._id}`);
            else if(cart.paymentMethod === "Stripe")
            navigate(`/orderStripe/${res._id}`);
        } catch (error) {
          toast.error(error);
        }
      };
      
      
      return (
        <>
          <CheckoutSteps step1 step2 step3 step4 />
          <Row>
            <Col md={8}>
              <ListGroup variant='flush' dir={isRTL ? 'rtl' : 'ltr'}>
                <ListGroup.Item>
                  <h2> {t('orders')} </h2>
                  <p>
                    <strong>{t('address')} </strong>
                    {cart.shippingAddress.namePrename}, {cart.shippingAddress.adress}{', '}
                    {cart.shippingAddress.mail}{', '}
                    {cart.shippingAddress.tel}
                  </p>
                </ListGroup.Item>
    
                <ListGroup.Item dir={isRTL ? 'rtl' : 'ltr'}>
                  <h2>{t('method')}</h2>
                  <strong>{t('method')} : </strong>
                  {cart.paymentMethod}
                </ListGroup.Item>
    
                <ListGroup.Item >
                  <h2>{t('ordpr')}</h2>
                  {cart.cartItems.length === 0 ? (
                    <Message> {t('emptycart')}  </Message>
                  ) : (
                    <ListGroup variant='flush'>
                      {cart.cartItems.map((item, index) => (
                        <ListGroup.Item key={index}>
                          <Row>
                            <Col md={1}>
                              <Image
                                src={item.image}
                                alt={item.name}
                                fluid
                                rounded
                              />
                            </Col>
                            <Col>
                              <Link to={`/products/${item.product}`}>
                                {item.name}
                              </Link>
                            </Col>
                            {/* selim */}
                            {/* <Col md={4}>
                              {item.qty} x ${item.price} = ${item.qty * item.price}
                            </Col> */}
                             {deviseInfo === "D_tn" && (
                               <Col md={4}>
                               {t('Price')} 
                               {item.qty} x {item.price} = {item.qty * item.price} {deviseInfo}
                              </Col>
                              )
                              }
                             {deviseInfo === "Euro" && (
                               <Col md={4}>
                               {t('Price')} 
                               {item.qty} x {item.priceEur} = {item.qty * item.priceEur} {deviseInfo}
                              </Col>
                              )
                              }
                              {deviseInfo === "Dolar" && (
                               <Col md={4}>
                               {t('Price')} 
                               {item.qty} x {item.priceDol} = {item.qty * item.priceDol} {deviseInfo}
                              </Col>
                              )
                              }

                          </Row>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  )}
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md={4}>
              <Card>
                <ListGroup variant='flush' >
                  <ListGroup.Item dir={isRTL ? 'rtl' : 'ltr'}>
                    <h2>{t('ordsum')}</h2>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col>{t('article')} :</Col>
                      {/* selim */}
                      {/* <Col> ${cart.itemsPrice} </Col> */}
                      {deviseInfo === "D_tn" && (
                      <Col>
                        <strong>{cart.itemsPrice} {deviseInfo}</strong>
                      </Col>
                      )
                      }
                      {deviseInfo === "Euro" && (
                      <Col>
                        <strong>{cart.itemsPriceEur} {deviseInfo}</strong>
                      </Col>
                      )
                      }
                      {deviseInfo === "Dolar" && (
                      <Col>
                        <strong>{cart.itemsPriceDol} {deviseInfo}</strong>
                      </Col>
                      )
                      }
                    </Row>
                  </ListGroup.Item>
                 
                  {/* <ListGroup.Item>
                    <Row>
                      <Col>Livraison :</Col>
                      <Col> ${cart.shippingPrice} </Col>
                    </Row>
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <Row>
                      <Col>Tax :</Col>
                      <Col> ${cart.taxPrice} </Col>
                    </Row>
                  </ListGroup.Item> */}

                  {/* <ListGroup.Item>
                    <Row>
                      <Col>Total :</Col>
                       selim 
                       <Col> ${cart.totalPrice} </Col> 
                      {deviseInfo === "D_tn" && (
                      <Col>
                        <strong>{cart.totalPrice} {deviseInfo}</strong>
                      </Col>
                      )
                      }
                      {deviseInfo === "Euro" && (
                      <Col>
                        <strong>{cart.totalPriceEur} {deviseInfo}</strong>
                      </Col>
                      )
                      }
                      {deviseInfo === "Dolar" && (
                      <Col>
                        <strong>{cart.totalPriceDol} {deviseInfo}</strong>
                      </Col>
                      )
                      }
                    </Row>
                  </ListGroup.Item> */}

                  <ListGroup.Item>
                    {error && <Message variant='danger'>{error}</Message>}
                  </ListGroup.Item>

                  <ListGroup.Item>
                    
                  <Button
                  type='button'
                  className='btn-block'
                  disabled={cart.cartItems.length === 0}
                  onClick={placeOrderHandler}
                  >
                                    {t('placeorder')}

                  </Button>
                  { isLoading && <Loader /> }
                  </ListGroup.Item>

                </ListGroup>
              </Card>
            </Col>
          </Row>
        </>
      );
    };
    
    export default PlaceOrderScreen;