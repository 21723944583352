import React, { useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

const SearchBox = () => {
  const [keyword, setKeyword] = useState('')
  const navigate = useNavigate()
  const { t } = useTranslation();

  const submitHandler = (e) => {
    e.preventDefault()
    if (keyword.trim()) {
      navigate(`/search/${keyword}`)
    } else {
      navigate('/')
    }
  }

  const handleInputChange = (e) => {
    setKeyword(e.target.value)
    submitHandler(e)
  }

  return (
    <Form onSubmit={submitHandler} className='d-flex'>
      <Form.Control
        type='text'
        name='q'
        onChange={handleInputChange}
        value={keyword}
        placeholder={t('searchby')} 
      ></Form.Control>
      <Button type='submit' variant='outline-success' className='p-2'>
      {t('search')} 
      </Button>
    </Form>
  )
}

export default SearchBox
