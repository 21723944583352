import { createSlice } from "@reduxjs/toolkit";

import { updateCart } from "../utils/cartUtils";


const initialState = localStorage.getItem('cart') ? JSON.parse
(localStorage.getItem("cart")) : {cartItems: [], shippingAddress: {}, paymentMethod: 'PayPal' };

/*
const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
}
*/


const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        addToCart: (state, action) => {
          const item = action.payload;

          const existItem = state.cartItems.find((x) => x._id === 
          item._id);

          if (existItem) {
            state.cartItems = state.cartItems.map((x) => x._id === 
            existItem._id ? item : x);
          } else {
            state.cartItems = [...state.cartItems, item];
          }
          
          return updateCart(state);
          
        },
        
        removeFromCart: (state, action) => {
           state.cartItems = state.cartItems.filter((x) => x._id !== action.payload);
           return updateCart(state);
        },

        //tarek3
        removeFromCartLocalStorage: (state, action) => {
          localStorage.removeItem('cart');
        },
        
        saveShippingAddress: (state, action) => {
          state.shippingAddress = action.payload;
          return updateCart(state);
        },
        
        savePaymentMethod: (state, action) => {
          state.paymentMethod = action.payload;
          return updateCart(state);
        },
       
        clearCartItems: (state, action) => {
          state.cartItems = [];
          return updateCart(state);
        }
        
    },
});


export const { addToCart, removeFromCart, 
  removeFromCartLocalStorage, saveShippingAddress,
savePaymentMethod, clearCartItems } = cartSlice.actions;



export default cartSlice.reducer;

