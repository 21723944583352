import { createSlice } from "@reduxjs/toolkit";


    // const initialState = localStorage.getItem('clictopay') ? JSON.parse
    //(localStorage.getItem("clictopay")) : {clictopayItems: ''};

const initialState = {
  clictopayInfo: localStorage.getItem('clictopayInfo')
    ? JSON.parse(localStorage.getItem('clictopayInfo'))
    : null,
};
  

const clictopaySlice = createSlice({
    name: "clictopay",
    initialState,
    reducers: {
        addToClictopay: (state, action) => {
            //const item = action.payload;

            //   state.clictopayItems = [...state.clictopayItems, item];


         state.clictopayInfo = action.payload;
         localStorage.setItem('clictopayInfo', JSON.stringify(action.payload));
         
             //state.itemsIdorder = state.clictopayItems.reduce((acc, item) =>
             //item.Idorder);

             //state.itemsIspaid = state.clictopayItems.reduce((acc, item) =>
             //item.Ispaid);

            // localStorage.setItem('clictopay', JSON.stringify(state));
          
        },
    },
});

export const { addToClictopay } = clictopaySlice.actions;

export default clictopaySlice.reducer;