//import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import { Link, useNavigate } from 'react-router-dom';
import {
  Row,
  Col,
  ListGroup,
  Image,
  Form,
  Button,
  Card,
} from 'react-bootstrap';
import { addToCart, removeFromCart } from '../slices/cartSlice';
import { FaTrash } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const CartScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  //selim
  const { deviseInfo } = useSelector((state) => state.devise);

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const addToCartHandler = async (product, qty) => {
    dispatch(addToCart({ ...product, qty }));
  };

  const removeFromCartHandler = async (id) => {
    dispatch(removeFromCart(id));
  };

  const checkoutHandler = () => {
    //navigate('/login?redirect=/shipping');
    navigate('/shipping');
  };

  return (
    <Row>
      <Col md={8}>
        <h1 style={{ marginBottom: '20px' }}>{t('cart')}</h1>
        {cartItems.length === 0 ? (
          <Message>
            {t('emptycart')} <Link to='/'>{t('return')}</Link>
          </Message>
        ) : (
          <ListGroup variant='flush'>
            {cartItems.map((item) => (
              <ListGroup.Item key={item._id}>
                <Row>
                  <Col md={2}>
                    <Image src={item.image} alt={item.name} fluid rounded />
                  </Col>
                  <Col md={3}>
                    <Link to={`/product/${item._id}`}>{item.name}</Link>
                  </Col>
                  {/* selim */}
                  {/* <Col md={2}>
                    ${item.price}
                  </Col> */}
                  {deviseInfo === 'D_tn' && (
                    <Col>
                      <strong>
                        {item.price} {deviseInfo}
                      </strong>
                    </Col>
                  )}
                  {deviseInfo === 'Euro' && (
                    <Col>
                      <strong>
                        {item.priceEur} {deviseInfo}
                      </strong>
                    </Col>
                  )}
                  {deviseInfo === 'Dolar' && (
                    <Col>
                      <strong>
                        {item.priceDol} {deviseInfo}
                      </strong>
                    </Col>
                  )}

                  <Col md={2}>
                    <Form.Control
                      as='select'
                      value={item.qty}
                      onChange={(e) =>
                        addToCartHandler(item, Number(e.target.value))
                      }
                    >
                      {[...Array(item.countInStock).keys()].map((x) => (
                        <option key={x + 1} value={x + 1}>
                          {x + 1}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                  <Col md={2}>
                    <Button
                      type='button'
                      variant='light'
                      onClick={() => removeFromCartHandler(item._id)}
                    >
                      <FaTrash />
                    </Button>
                  </Col>
                </Row>
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </Col>

      <Col md={4}>
        <Card>
          <ListGroup variant='flush'>
            <ListGroup.Item>
              <h2>
                {t('total')} (
                {cartItems.reduce((acc, item) => acc + item.qty, 0)})
                {t('article')}
              </h2>
              {/* selim */}
              {/* Prix : { cartItems
                          .reduce((acc, item) => acc + item.qty * item.price, 0)
                          .toFixed(2)} */}
              {deviseInfo === 'D_tn' && (
                <h4>
                  Prix :{' '}
                  {cartItems
                    .reduce((acc, item) => acc + item.qty * item.price, 0)
                    .toFixed(2)}{' '}
                  {deviseInfo}
                </h4>
              )}
              {deviseInfo === 'Euro' && (
                <h4>
                  Prix :{' '}
                  {cartItems
                    .reduce((acc, item) => acc + item.qty * item.priceEur, 0)
                    .toFixed(2)}{' '}
                  {deviseInfo}
                </h4>
              )}
              {deviseInfo === 'Dolar' && (
                <h4>
                  Prix :{' '}
                  {cartItems
                    .reduce((acc, item) => acc + item.qty * item.priceDol, 0)
                    .toFixed(2)}{' '}
                  {deviseInfo}
                </h4>
              )}
            </ListGroup.Item>
            <ListGroup.Item>
              <Button
                type='button'
                className='btn-block'
                disabled={cartItems.length === 0}
                onClick={checkoutHandler}
              >
                {t('proceedcheckout')}
              </Button>
            </ListGroup.Item>
          </ListGroup>
        </Card>
      </Col>
      <Helmet>
        <title> Licorne Professional Academy - Panier </title>
        <meta name="description" content="Licorne Professional Academy - Panier" />
        <link rel='canonical' href='https://licorne-academy.store/cart' />
      </Helmet>
    </Row>
  );
};

export default CartScreen;
