import { Container } from "react-bootstrap";
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import Header from "./components/Header";
import Footer from "./components/Footer";
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';


const App = () => {
  return (
    <>
            <I18nextProvider i18n={i18n}>
      <Header />
      <main className="py-3">
        <Container>
          <Outlet />
        </Container>
      </main>
      <Footer />
      <ToastContainer />
            </I18nextProvider>
    </>
  )
}

export default App