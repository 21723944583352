import { PRODUCTS_URL, UPLOAD_URL } from '../constants';
import { apiSlice } from './apiSlice';

export const productsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProducts: builder.query({
      query: ({ keyword, pageNumber, sortBy }) => ({
        url: PRODUCTS_URL,
        params: {
          keyword,
          pageNumber,
          sortBy: sortBy || 'position_asc',
        },
      }),
      providesTags: ['Products'],
      //5 seconds
      keepUnusedDataFor: 5,
    }),

    getProductDetails: builder.query({
      query: (productId) => ({
        url: `${PRODUCTS_URL}/${productId}`,
      }),
      keepUnusedDataFor: 5,
    }),

    createProduct: builder.mutation({
      query: () => ({
        url: `${PRODUCTS_URL}`,
        method: 'POST',
      }),
      invalidatesTags: ['Product'],
    }),

    updateProduct: builder.mutation({
      query: (data) => ({
        url: `${PRODUCTS_URL}/${data.productId}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Products'],
    }),

    uploadProductImage: builder.mutation({
      query: (data) => ({
        //url: `/api/upload`,
        url: `${UPLOAD_URL}`,
        method: 'POST',
        body: data,
      }),
    }),

    deleteProduct: builder.mutation({
      query: (productId) => ({
        url: `${PRODUCTS_URL}/${productId}`,
        method: 'DELETE',
      }),
      providesTags: ['Product'],
    }),
    /*
          createReview: builder.mutation({
            query: (data) => ({
              url: `${PRODUCTS_URL}/${data.productId}/reviews`,
              method: 'POST',
              body: data,
            }),
            invalidatesTags: ['Product'],
          }),

          getTopProducts: builder.query({
            query: () => `${PRODUCTS_URL}/top`,
            keepUnusedDataFor: 5,
          }),
       */
          createProductWithPosition: builder.mutation({
            query: (productData) => ({
              url: PRODUCTS_URL,
              method: "POST",
              body: productData,
            }),
            invalidatesTags: ["Product"],
          }),
      
          // Update a product with position handling
          updateProductWithPosition: builder.mutation({
            query: ({ productId, ...productData }) => ({
              url: `${PRODUCTS_URL}/${productId}`,
              method: "PUT",
              body: productData,
            }),
            invalidatesTags: ["Product"],
          }),
      
          // Get product position
          getProductPosition: builder.query({
            query: (productId) => `${PRODUCTS_URL}/${productId}/position`,
          }),
      
          // Sort products
          sortProducts: builder.query({
            query: (sortBy) => ({
              url: `${PRODUCTS_URL}/sort/${sortBy}`,
            }),
          }),    
  }),
});

export const {
  useGetProductsQuery, 
  useGetProductDetailsQuery, 
  useCreateProductMutation, 
  useUpdateProductMutation,
useUploadProductImageMutation,
useDeleteProductMutation, 
useUpdateProductWithPositionMutation,useCreateProductWithPositionMutation,
useGetProductPositionQuery, 
useSortProductsQuery,
} = productsApiSlice;
